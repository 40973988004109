import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { WebSocketContext } from '../../components/WebSocket';
import PickBans from '../../components/PickBans';
import './MatchSetup.css';

const MatchSetup = ({ uuid }) => {
  const ws = useContext(WebSocketContext);
  const user = useSelector((state) => state.auth.user);
  const match = useSelector((state) => state.match);

  const banMap = (matchId, mapName) => {
    ws.sendCommand('banMap', { matchId, mapName });
  };

  return (
    <div className="container py-5 text-white card d-flex flex-row justify-content-center">
      <PickBans match={match} user={user} banFn={banMap} />
    </div>

  );
};

export default MatchSetup;
