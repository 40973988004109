import CountDown from '../CountDown';
import './AcceptMatch.css'

const AcceptMatchDialog = ({ timer, handleAccept, handleDecline, autoDeclineFn }) => (
    <div>
        <h4 className="nav justify-content-center text-white h4">Jogo encontrado!</h4>
        <hr className="line" />  
        <div className="container text-white">
            <div className="row justify-content-md-center">
                <div className="col-md-auto disclamer">
                Ao aceitares o jogo comprometes-te a jogar a partida até ao fim.
                Cada partida pode ter até 60 minutos de duração.
                </div>
            </div>
            <div className="row justify-content-md-center timer">
                <p style={{fontSize: '14px'}}>Expira em:</p>
                <div className="col-md-auto">
                    <span className="timeleft display-4">
                        <CountDown timer={timer} onCompleteFn={autoDeclineFn} />
                    </span>
                </div>
            </div> 
        </div>
        <div className="container btn-group" role="group" aria-label="Aceitar jogo" >
            <button type="button" className="btn btn-danger btn-sm" onClick={handleDecline}>
            Cancelar
            </button>
            <button type="button" className="btn btn-success btn-sm" onClick={handleAccept}>
            Aceitar
            </button>
        </div>
    </div>
)

export default AcceptMatchDialog
