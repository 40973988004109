import './Footer.css';

function Footer() {
  return (
    <footer className="text-white w-full text-center text-xs">
      <p>
        CS:GO™ é marca registrada da Valve Corporation®. Este site não está associado nem recebe apoio da Valve Corporation®
      </p>
    </footer>
  );
}

export default Footer;
