import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { timeAgo } from '../../helpers/date';
import ProfilePicture from '../../components/ProfilePicture';
import TeamTableStats from './TeamTableStats.js';
import { ReactComponent as LeaderSVG } from '../../components/IconSvg/crown-leader.svg';

// import cap from './crosshairs-solid.svg';
import './Match.css';

const Match = ({ uuid }) => {
  const [match, setMatch] = useState({});
  const [teamA, setTeamA] = useState([]);
  const [teamB, setTeamB] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/match/${uuid}`)
      .then((res) => res.json())
      .then((match) => {
        setMatch(match);
        setTeamA(match.Users.filter((user) => user.stats.team === 'a'));
        setTeamB(match.Users.filter((user) => user.stats.team === 'b'));
      })
      .catch((err) => {
        console.error(err)
        setMatch({id: 'not_found'});
      });
  }, [setMatch, uuid]);

  const aPlayers = teamA.map((player) => (
    <Link
      to={`/u/${player.username}`}
      key={player.id}
      className="team-player d-flex align-items-center"
    >
      <div>
        {!!player.stats.captain && (
          <LeaderSVG className="team-captain-a" />
        )}
        <ProfilePicture picture={player.picture} />
      </div>
      <div className="d-flex flex-column align-items-start pl-3">
        <h4 className="match-player-username my-0 mt-3">{player.username}</h4>
        <p className="text-secondary">#{player.id}</p>
      </div>
    </Link>
  ));
  const bPlayers = teamB.map((player) => (
    <Link
      to={`/u/${player.username}`}
      key={player.id}
      className="team-player d-flex flex-row-reverse align-items-center"
    >
      <div>
        <ProfilePicture picture={player.picture} />
        {!!player.stats.captain && (
          <LeaderSVG className="team-captain-b" />
        )}
      </div>
      <div className="d-flex flex-column align-items-end pr-3">
        <h4 className="match-player-username my-0 mt-3">{player.username}</h4>
        <p className="text-secondary">#{player.id}</p>
      </div>
    </Link>
  ));

  return (
    <div className="container">
      {match.id==='not_found' ? <div className="d-flex justify-content-center align-items-center mt-5 pt-5">
        <h4 className="text-white">UPS..., parece que este jogo não existe. Volta à <Link to="/" className="text-primary">página inicial</Link>.</h4>
      </div> :
      <div className="card text-white pl-0 d-flex flex-column align-items-center">
        <div className="match-header d-flex justify-content-center mb-4">
          <div className="team d-flex flex-column align-items-start justify-content-around">
            <h4 className="h4 mb-4">Equipa A</h4>
            <div className="team-players d-flex flex-column justify-content-around">
              {aPlayers}
            </div>
          </div>
          <div className="match-vs d-flex flex-column">
            <div className="text-center">{match.map}</div>
            <div className="d-flex  justify-content-center align-items-center">
              <h4 className="match-score">{match.teamAScore}</h4>
              <h4 className="match-score px-1 pb-2">:</h4>
              <h4 className="match-score">{match.teamBScore}</h4>
            </div>
            <h4 className="text-center match-stage">{match.stage}</h4>
            <div className="text-center">{timeAgo(match.createdAt)}</div>
          </div>
          <div className="team d-flex flex-column align-items-end justify-content-around">
            <h4 className="h4 mb-4">Equipa B</h4>
            <div className="team-players d-flex flex-column justify-content-around">
              {bPlayers}
            </div>
          </div>
        </div>
        <hr className="bg-secondary w-50 align-self-center my-5" />
        <TeamTableStats teamName="Equipa A" team={teamA} />
        <TeamTableStats teamName="Equipa B" team={teamB} />
      </div>}
    </div>
  );
};

export default Match;
