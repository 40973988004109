import { useState } from 'react';
import copy from 'copy-to-clipboard';
import CountDown from '../CountDown';
import eyeSvg from '../IconSvg/eye.svg';
import eyeSlashSvg from '../IconSvg/eye-slash.svg';
import clipboardSvg from '../IconSvg/clipboard-check.svg';

function ServerDetails({ serverIp, map, timer }) {
  const [ icon, setIcon ] = useState(eyeSvg);
  const [ inpType, setInpType ] = useState('password');

  const copyThis = () => {
    copy(`connect ${serverIp}`);
  };

  const changeView = () => {
    if (inpType === 'password') {
      setInpType('text');
      setIcon(eyeSlashSvg);
    } else {
      setInpType('password');
      setIcon(eyeSvg);
    }
  }

  return (
    <div className="mr-5">
      <h2 className="text-white"><CountDown timer={timer} onCompleteFn={() => {}} /></h2>
      <h4 className="text-white py-2">Informações do jogo</h4>
      <div
              className={'media map-entry my-2 shadow'}
              key={map}
            >
              <img
                src={`/maps/${map}.jpg`}
                alt="..."
                className="align-self-end mr-3"
                width="120"
              />
              <h5 className="text-white map-name">{map}</h5>
            </div>
      <div className="input-group py-2">
        <input
          type={inpType}
          value={`connect ${serverIp}`}
          style={{
            backgroundColor: 'rgb(30, 30, 30)',
          }}
          className={`text-white form-control form-control`}
          readOnly={true}
          spellCheck="false"
          aria-label="server-info-input"
          onClick={(event) => event.target.select()}
        />
        <button className="btn bg-theme-blue rounded-0" type="button" onClick={() => changeView()}>
          <img
            src={icon}
            alt="mostrar ocultar password"
            className="icon-invert"
          />
        </button>
        <button
          className="btn bg-theme-blue rounded-0"
          type="button"
          onClick={() => copyThis()}
        >
          <img
            src={clipboardSvg}
            alt="copiar password"
            className="icon-invert"
          />
        </button>
      </div>
      <div className="d-flex justify-content-center py-2">
        <a href={`steam://connect/${serverIp}`}>Entrar no servidor</a>
      </div>
    </div>
  );
}

export default ServerDetails;
