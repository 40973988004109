import Icon from '../ui/Icon';
import Image from '../ui/Image';

const ProfileCard = ({ user, type, handleLogout }) => {
  switch (type) {
    case 'logout':
      return (
        <div className="flex items-center h-full justify-start p-2 sidebar-separator-bottom">
          <Image path={user.picture} className="w-10 h-10" />
          <div className="block ml-2 font-bold ">{user.username}</div>
          <div className="flex-grow block " />

              <div onClick={handleLogout} className='cursor-pointer' >
                <Icon id='logout' fill='white' viewBox='0 0 511.996 511.996' />
              </div>

        </div>
      );
    default:
      return <div>Yooo</div>;
  }
};



export default ProfileCard;
