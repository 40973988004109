import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import TeamMember from './TeamMember';
import SearchSvg from '../IconSvg/search.svg';

const InviteTeam = ({ closeModal, ws, lobbyId }) => {
  const [search, setSearch] = useState('');
  const onlineList = useSelector((state) => state.auth.onlineFriends);
  const inviteFriend = (id) => {
    ws.sendCommand('inviteFriend', { id, lobbyId });
    closeModal();
  };
  return (
    <div>
      <div className="flex align-items-center justify-between ">
        <div className="text-white text-xl">Convidar para a partida</div>
        <div>
          <button
            className="text-red-800 font-bold flex-grow text-xl"
            onClick={closeModal}
          >
            &#10005;
          </button>
        </div>
      </div>
      <div className="mt-2 mb-3">
        <input
          className="friend-list-search-input"
          type="text"
          placeholder="Procurar amigos"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button className="friend-list-search-btn">
          <img src={SearchSvg} alt="search friend" />
        </button>
      </div>
      <div className="flex text-white mb-2">
        <p>Disponíveis</p>
        <p className="ml-2">({onlineList.length ?? 0})</p>
      </div>
      {onlineList
        .filter(
          (a) => a.username.toLowerCase().indexOf(search.toLowerCase()) > -1
        )
        .map((a) => (
          <TeamMember
            key={a.username}
            image={a.picture}
            name={a.username}
            steamid={a.steamid}
            invite={true}
            inviteFn={inviteFriend}
          />
        ))}
    </div>
  );
};

export default InviteTeam;
