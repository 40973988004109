import { Link } from 'react-router-dom';
import Icon from './Icon';

function MenuItem({
  item: { id, title, notifications, to, viewBox },
  onClick,
  selected,
}) {
  return (
    <Link
      className={`w-full flex items-center px-3 justify-start mt-3 cursor-pointer ${
        selected === id ? 'sidebar-item-selected' : 'sidebar-item'
      }`}
      onClick={() => onClick(id)}
      to={to}
    >
      <Icon id={id} viewBox={viewBox} />
      <div className="block ml-2">{title}</div>
      <div className="block flex-grow" />
      {notifications && (
        <div className="flex bg-red-600  w-5 h-5 flex items-center justify-center rounded-full mr-2">
          <div className="text-white text-sm">{notifications}</div>
        </div>
      )}
    </Link>
  );
}

export default MenuItem;
