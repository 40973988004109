import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import authReducer from './authSlice';
import lobbyReducer from './lobbySlice';
import matchReducer from './matchSlice';


const reducers = combineReducers({
  auth: authReducer,
  lobby: lobbyReducer,
  match: matchReducer,
})

// https://github.com/rt2zz/redux-persist#readme
const persistConfig = {
  key: 'app',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

let persistor = persistStore(store);

// eslint-disable-next-line import/no-anonymous-default-export
export { store, persistor };
