import { useState, useContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import {
  loginUserAsync,
  logoutUserAsync,
  friendRequestAsync,
} from '../../store/authSlice';
import {
  selectCurrentLobby,
  leaveLobbyAsync,
  clearInvited,
} from '../../store/lobbySlice';
import { WebSocketContext } from '../../components/WebSocket';
import { setUsernameAsync, usernameIsTaken } from '../../store/authSlice';
import Home from '../../containers/Home';
import About from '../../containers/About';
import Admin from '../../containers/Admin';
import LobbyPage from '../../containers/Lobby';
import QueuePage from '../../containers/Queue';
import UserPage from '../../containers/User';
import MatchSetup from '../../containers/MatchSetup';
import Match from '../../containers/Match';
import MatchList from '../../containers/MatchList';
import Profile from '../../containers/Profile';
import NotFound from '../../containers/NotFound';
import Ranking from '../../containers/Ranking';
import Footer from '../../components/Footer';
import AcceptMatch from '../../components/AcceptMatch';
import AcceptInvite from '../../components/AcceptInvite';
import SelectUsername from '../../components/SelectUsername';
import Lobby from '../../components/Lobby';
import Modal from '../../components/NewModal';
import InviteTeam from '../../components/InviteTeam/InviteTeam';
import Sidebar from '../../components/Sidebar/Sidebar';
import Icon from '../../components/ui/Icon';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const { foundMatch, matchPhase } = useSelector((state) => state.match);
  const lobby = useSelector((state) => state.lobby);
  const auth = useSelector((state) => state.auth);
  const ws = useContext(WebSocketContext);
  const dispatch = useDispatch();
  const { inParty: inLobby, isLeader, inQueue, id: lobbyId } = useSelector(
    selectCurrentLobby
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const submitData = (username) => {
    dispatch(setUsernameAsync(username));
  };

  const handleLogin = () => {
    const popupWindow = window.open(
      `${process.env.REACT_APP_API}/auth/steam`,
      '_blank',
      'width=800, height=600'
    );

    if (window.focus) popupWindow.focus();
  };

  const createLobby = () => {
    ws.sendCommand('createLobby', '');
  };

  // lobby fns
  const handlePlay = () => {
    ws.sendCommand('addToQueue', lobby.id);
  };

  const handleAddFriend = () => {
    ws.sendCommand('friendList', '');
    setModalIsOpen(true);
  };

  const handleLeaveParty = () => {
    dispatch(leaveLobbyAsync());
    ws.sendCommand('leaveLobby', '');
  };

  const handleStopQueue = () => {
    ws.sendCommand('stopQueue', lobby.id);
  };

  const acceptPartyInvite = (lobbyId) => {
    ws.sendCommand('joinLobby', lobbyId);
    dispatch(clearInvited())
  }
  const declinePartyInvite = () => {
    dispatch(clearInvited())
  }
  // end lobby fns


  const acceptFriend = (id) => {
    dispatch(friendRequestAsync(id, 1))
  }
  const declineFriend = (id) => {
    dispatch(friendRequestAsync(id, 2))
  }

  // keep user loggedin up to date
  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch(loginUserAsync(auth.token)); 
    }
  }, [auth.isAuthenticated, auth.token, dispatch]);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.origin !== process.env.REACT_APP_API) {
        return;
      }

      const { token, ok } = event.data;
      if (ok) {
        try {
          dispatch(loginUserAsync(token));
          ws.sendCommand('authorization', { Bearer: token });
        } catch (e) {
          console.error(e);
        }
      }
      return () => window.removeEventListener('message');
    });
  }, [dispatch, ws]);

  return (
    <div className="flex">
      <Sidebar 
        isAuthenticated={auth.isAuthenticated}
        user={auth.user}
        handleLogin={() => handleLogin()}
        handleLogout={() => dispatch(logoutUserAsync())}
        openLobby={() => createLobby()}
        inQueue={inQueue}
        showPlayBtn={!inLobby}
        declineFriendFn={declineFriend}
        acceptFriendFn={acceptFriend}
        >
        {auth.isAuthenticated && inLobby && (
          <Lobby
            play={handlePlay}
            addFriend={handleAddFriend}
            leaveParty={handleLeaveParty}
            stopQueue={handleStopQueue}
            isLeader={isLeader}
            inQueue={inQueue}
            lobby={lobby.currentLobby}
          />
        )}
        <Modal
          modalIsOpen={modalIsOpen}
          childElement={
            <InviteTeam
              ws={ws}
              lobbyId={lobbyId}
              closeModal={() => setModalIsOpen(false)}
            />
          }
        />
        </Sidebar>
      <div className="flex w-full">
        <div className="w-full h-screen w-64 flex-shrink-0">
          .
        </div>
        <div className=" h-screen flex-grow overflow-x-hidden overflow-auto flex flex-wrap content-start p-2">

        <div className="w-full flex p-2 items-end">
          <div className="flex-grow flex justify-between">
            {auth.isAuthenticated &&
            <div className="flex items-center">
              <div className="text-2xl font-bold text-white">Hey {auth.user.username}</div>
              <div className='flex items-center p-2 ml-2 rounded-xl bg-gray-800 font-semibold text-blue-500 text-xs'>
                  insider
              </div>
            </div>}
          </div>
            <div className="w-full sm:w-56 mt-4 sm:mt-0 relative">
              <Icon
                id="res-react-dash-search"
                viewBox='0 0 285 284'
                className="w-5 h-5 search-icon left-3 absolute"
                fill='none'
              />
                <input
                  type="text"
                  className="pl-12 py-2 pr-2 block w-full rounded-lg border-gray-300 bg-card focus:outline-none"
                  placeholder="pesquisar"
                />
            </div>
          </div>

          <Routes>
              <Route path="/" element={<Home isAuthenticated={auth.isAuthenticated} user={auth.user} token={auth.token} />} />
              <Route path="/about" element={<About />} />
              <Route path="/admin/" element={<Admin />}>
                <Route path="lobby" element={<LobbyPage />} />
                <Route path="user" element={<UserPage />} />
                <Route path="queue" element={<QueuePage />} />
              </Route>
              <Route path="/u/:user" element={<Profile isAuthenticated={auth.isAuthenticated} loggedUser={auth.user} />} />
              <Route path="/leaderboard" element={<Ranking />} />
              <Route path="/matches" element={<MatchList />} />
              <Route path="/m/:uuid" element={<Match />} />
              <Route path="/ms/:uuid" element={<MatchSetup />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
          </div>
      </div>
      {/* Modals */}
      {auth.isAuthenticated && !auth.user?.username && (
        <SelectUsername
          user={auth.user}
          usernameInUse={auth.usernameAlreadyTaken}
          submitData={submitData}
          clearAlreadyInUse={() => dispatch(usernameIsTaken(false))}
        />
      )}
      {foundMatch && matchPhase === 'accept' && <AcceptMatch />}
      {lobby.invited && (
        <AcceptInvite
          lobbyId={lobby.invitedLobby}
          user={lobby.invitedby}
          acceptFn={acceptPartyInvite}
          declineFn={declinePartyInvite}
        />
      )}
      <ToastContainer />
    </div>
  );
}

export default App;
