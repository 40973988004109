import { useState, useEffect, Fragment } from 'react';
import { pad } from '../../helpers/date';

const CountDown = ({ timer, onCompleteFn }) => {
  const [timeLeft, setTimeLeft] = useState(
    Math.floor(((+new Date(timer) - +new Date()) / 1000) % 60)
  );
  const [minutesLeft, setMinutesLeft] = useState(
    Math.floor(((+new Date(timer) - +new Date()) / (1000 * 60)) % 60)
  );

  useEffect(() => {
    let timerToClean;
    if (Math.floor(((+new Date(timer) - +new Date()) / 1000)) > 0) {
      timerToClean = setTimeout(() => {
        setTimeLeft(pad(Math.floor(((+new Date(timer) - +new Date()) / 1000) % 60)));
        setMinutesLeft(pad(Math.floor(
          ((+new Date(timer) - +new Date()) / (1000 * 60)) % 60
        )));
      }, 1000);
    } else {
      setTimeLeft(0);
      onCompleteFn();
    }

    return () => {
      clearTimeout(timerToClean);
    };
  }, [timeLeft, timer, setTimeLeft, minutesLeft, setMinutesLeft, onCompleteFn]);
  return (
    <Fragment>
      {minutesLeft > 0 && minutesLeft} {minutesLeft > 0 && ' : '}
      {timeLeft}
    </Fragment>
  );
};

export default CountDown;
