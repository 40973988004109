import { useEffect, useState } from 'react';
import Profile from '../../components/Profile';

function Home({ isAuthenticated, user, token }) {
  const [currentUser, setCurrentUser] = useState({});
  // TODO: move to app shell
  useEffect(() => {
    if (isAuthenticated) {
      fetch(`${process.env.REACT_APP_API}/user/${user.username}`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((profile) => setCurrentUser(profile))
        .catch((err) => {
          console.error(err);
        });
    }
  }, [token, setCurrentUser, user.username, isAuthenticated]);

  if (isAuthenticated) {
    return <Profile user={currentUser} />;
  }

  return (
    <div className="w-full p-2">
      <div className="rounded-lg bg-card overflow-hidden h-80">
        <div>
          <div className="w-full h-20 add-component-head" />
          <div
            className="flex flex-col items-center"
            style={{
              transform: 'translate(0, -40px)',
            }}
          >
            <div className="text-white font-bold mt-3">
              O que é isto?
            </div>
            <div className="mt-2">Se não sabes é porque não foste convidado.</div>
            <div className="mt-1">Ai e tal sou leet e devia estar aqui, well guess what who cares?</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
