import Image from '../ui/Image';
import PlayButton from '../PlayButton';

function Lobby({ play, addFriend, leaveParty, stopQueue, isLeader, inQueue, lobby }) {
  return (
      <div className="h-full w-full item-center flex-none space-y-2">
        <div className="flex ml-2 item-center space-x-2">
          {lobby.map((a, i) => (
            <Image 
              path={a.picture}
              className='w-6 h-6'
              key={i}
            />
          ))}
        {lobby.length < 5 && (
          <button className="border-dashed border space-x-2 rounded-full w-6 h-6 text-white text-2xl leading-3 col-span-6" onClick={addFriend}>
            +
          </button>
        )}
        </div>
        <div className='flex'>
        <div onClick={leaveParty} className="text-red-600 col-span-6">
          X        
        </div>
          <PlayButton inQueue={inQueue} playFn={play} stopFn={stopQueue} />
        </div>
      </div>
  );
}

export default Lobby;
