import { useState, useEffect } from 'react';
import RankingItem from '../../components/RankingItem/RankingItem';
import './Ranking.css';

const Ranking = () => {
  const [ranking, setRanking] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/leaderboard`)
      .then((res) => res.json())
      .then((rank) => setRanking(rank))
      .catch((err) => {
        console.error(err);
      });
  }, [setRanking]);

  const podium = ranking.slice(0, 3).map((rankNumber, i) => (
    <RankingItem
      key={i}
      index={i + 1}
      playerName={rankNumber.username}
      playerPicture={rankNumber.picture}
      points={rankNumber.elo}
      podium="true"
    />
  ));

  const rank = ranking.map((rankNumber, i) => (
    <RankingItem
      key={i}
      index={i + 1}
      playerName={rankNumber.username}
      playerPicture={rankNumber.picture}
      points={rankNumber.elo}
    />
  ));

  return (
    <div className="container d-flex flex-column align-items-center">
      <h2 className="card-header">Classificacão global</h2>
      <div className="ranking-podium d-flex">
        {podium}
      </div>
      <div className="ranking-table d-flex flex-column align-items-center mt-4">
        {rank}
      </div>
    </div>
  );
};

export default Ranking;
