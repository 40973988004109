import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function Admin() {
  const auth = useSelector((state) => state.auth);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/users/list`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((res) => res.json())
      .then((resp) => setUsers(resp));
  }, [auth.token, setUsers]);
  return (
    <div className="container">
      <h3>Gerir Users</h3>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">users</th>
            <th scope="col">actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((u) => (
            <tr key={`user_${u.id}`}>
              <th>{u.id}</th>
              <td>{u.username}</td>
              <td>ban/edit</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Admin;
