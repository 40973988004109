import ProfilePicture from '../ProfilePicture';
import {ReactComponent as CrownSVG} from '../../components/IconSvg/crown-leader.svg';
import {ReactComponent as SteamSVG} from '../IconSvg/steam.svg';

import './MiniProfile.css';

const MiniProfile = ({ user, team }) => {
  return (
    <div className={`mini-profile d-flex ${team==='b' && 'flex-row-reverse'} align-items-center bg-container-accent p-2 px-4 m-2 text-white`}>        
      <ProfilePicture picture={user.picture} alt='avatar' size="mini-profile-picture border-blue" />
      <div className={`d-flex flex-column ${team==='b' && 'align-items-end'} justify-content-center px-3 mini-profile-name`}>
        <p className="font-weight-normal mb-0 text-uppercase f-play">
          {user.username}</p>
        <a href={`https://steamcommunity.com/profiles/${user.steamid64}`} target="_new" className="no-underline small" >
          <SteamSVG className="steam-profile-mini"/>
          <p className="small mb-0 text-truncate text-white">{user.name}</p>
        </a>
      </div>
      <div className="">
        {user.captain && <CrownSVG className="cap-miniprofile" /> }
      </div> 
    </div>
  );
}

export default MiniProfile;
