import CountDown from '../CountDown';
import Accepted from '../IconSvg/person-check.svg';
// import Declined from '../IconSvg/person-x.svg';
import Waiting from '../IconSvg/person.svg';

const WaitingPlayers = ({ timer, match, onComplete }) => {
	const waitA = 5 - match.acceptsA;
	const waitB = 5 - match.acceptsB;
	return (
    <div className="waiting-players text-white">
		<h4 className="nav justify-content-center text-white h4">Jogo encontrado!</h4>
        <hr className="line" />  
		<div>
			<h4 className="text-center h6">A aguadar os jogadores</h4>
			<p className="row justify-content-md-center mb-0 mt-0">Expira em:</p>
			<div className="row justify-content-md-center timer">
				<span className="timeleft"><CountDown timer={timer} onCompleteFn={onComplete} /></span>
			</div>
		</div>
		<div>
			<div className="row waiting-players-list justify-content-center">
				{[...Array(match.acceptsA)].map((_, i) => <img  src={Accepted} className="accpeted-match m-2" alt="accepted" key={`accepted_${i}`} />)}
				{[...Array(waitA)].map((e, i) => <img key={`waiting_${i}`} src={Waiting} className="waiting-match m-2" alt={`waiting_${i}`} />)}
				{/* {[...Array(match.declines)].map((e, i) => <img src={Declined} className="declined-match m-2" alt="declined" key={`declined_${i}`} />)} */}
			</div>
			<div className="row waiting-players-list justify-content-center vs">
				<h4>VS</h4>
			</div>
			<div className="row waiting-players-list justify-content-center">
				<div className="waiting-players-bar"></div>
				{[...Array(match.acceptsB)].map((_, i) => <img  src={Accepted} className="accpeted-match m-2" alt="accepted" key={`accepted_${i}`} />)}
				{[...Array(waitB)].map((e, i) => <img key={`waiting_${i}`} src={Waiting} className="waiting-match m-2" alt={`waiting_${i}`} />)}
			</div>
		</div>
    </div>
)
}

export default WaitingPlayers
