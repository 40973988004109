import { Link } from 'react-router-dom';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';
import './RankingItem.css';

const RankingItem = ({ index, playerName, playerPicture, points, podium }) => {
    return (
        <>
            {podium ?<div className="ranking-item-podium d-flex flex-column align-items-center mx-5 my-5">
              <span>#{index}</span>
              {playerPicture && <div className="ranking-item-picture d-flex justify-content-center align-items-center mx-2">
                <ProfilePicture picture={playerPicture} size="ranking-podium-picture" />
              </div>}
              <Link to={`/u/${playerName}`} className="font-weight-bold mt-3"><h4 className="my-0">{playerName}</h4></Link>
              <h4 className="ranking-item-points font-weight-bold my-0 py-0">{points}pts</h4>
            </div> 
            :<div className="ranking-item d-flex align-items-center p-0 py-1 mx-1 mb-1 text-white">
              <span className="mx-2 px-0 ranking-index d-flex justify-content-center">#{index}</span>
              {playerPicture && <ProfilePicture picture={playerPicture} size="ranking-table-picture mx-2" />}
              <Link to={`/u/${playerName}`} className="font-weight-bold my-0 ranking-table-player-username"><h4 className="py-0 my-0">{playerName}</h4></Link>
              <p className="ranking-item-points ranking-table-points font-weight-bold my-0 py-0 px-3">{points}pts</p>
            </div>}
        </>
    );
}

export default RankingItem
