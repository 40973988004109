import { Link } from 'react-router-dom';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';
import { timeAgo } from '../../helpers/date';
import { ReactComponent as UserValidated } from '../../components/IconSvg/patch-check.svg';
import './Profile.css';
const Profile = ({ user }) => {
  const renderResult = (scoreA, scoreB, team) => {

    if (scoreA === scoreB) {
      return <span className="text-warning">Empate</span>;
    }

    if ((scoreA > scoreB && team === 'a') || (scoreB > scoreA && team === 'b')) {
      return <span className="text-success">Vitoria</span>;
    } 
    
    return <span className="text-danger">Derrota</span>;
  }

  return (
    <div className="container">
        <div className="card pl-0 d-flex flex-column align-items-center">
          <div className="profile-player py-5 d-flex flex-column align-items-center justify-content-center">
            <ProfilePicture
              picture={user.picture}
              size="profile-player-picture"
            />
            <h4 className="text-secondary mt-2">#{user.id}</h4>
            <h4 className="text-white mb-4 d-flex align-items-center">
              {user.username}
              {user.isVerified && (
                <UserValidated className="ml-2 svg-blue" />
              )}
            </h4>
          </div>
          <nav className="profile-nav d-flex flex-row justify-content-around py-0 mt-2 text-white">
            <h4 className="font-weight-normal d-flex align-items-center justify-content-center">
              Geral
            </h4>
            <h4 className="font-weight-normal d-flex align-items-center justify-content-center">
              Equipas
            </h4>
            <h4 className="font-weight-normal d-flex align-items-center justify-content-center">
              Jogos
            </h4>
            <h4 className="font-weight-normal d-flex align-items-center justify-content-center">
              Amigos
            </h4>
          </nav>

          <div className="row" style={{ flexWrap: 'nowrap' }}>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center my-1 text-white">
                    <div className="col">
                      <h4 className="mb-0">{(((user?.stats?.kills + user?.stats?.assist)/user?.stats?.death) || 0).toFixed(2) }</h4>
                      <p className="small text-muted mb-0">KDA</p>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center my-1 text-white">
                    <div className="col">
                      <h4 className="mb-0">{user?.stats?.kills}</h4>
                      <p className="small text-muted mb-0">Mataste</p>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center my-1 text-white">
                    <div className="col">
                      <h4 className="mb-0">{user?.stats?.death}</h4>
                      <p className="small text-muted mb-0">Morreste</p>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>

          <div className="team-table-stats">
            <table className="table table-borderless text-white mb-5">
              <thead>
                <tr>
                  <th scope="col" className="font-weight-normal text-uppercase">Data</th>
                  <th scope="col" className="text-right font-weight-normal text-uppercase">Modo</th>
                  <th scope="col" className="text-right font-weight-normal text-uppercase">Resultado</th>
                  <th scope="col" className="text-right font-weight-normal text-uppercase">Pontuação</th>
                  <th scope="col" className="text-right font-weight-normal text-uppercase">Mapa</th>
                  <th scope="col" className="text-right font-weight-normal text-uppercase"></th>
                </tr>
              </thead>
              <tbody>
              {user?.matches?.map((u) => (
                  <tr key={u.matchId} className="team-table-tr">
                    <td className="d-flex align-items-center py-1">
                      {timeAgo(u.createdAt)}
                    </td>
                    <td className="text-right">{u.gameMode}</td>
                    <td className="text-right">{renderResult(u.teamAScore, u.teamBScore, u.Users[0].team)}</td>
                    <td className="text-right">{u.teamAScore} - {u.teamBScore}</td>
                    <td className="text-right">{u.gameMode}</td>
                    <td className="text-right">{u.map} <img src={`/maps/${u.map}.jpg`} alt="mapa" width="50" /></td>
                    <td className="text-right"><Link to={`/m/${u.matchId}`} > Detalhes </Link></td>
                  </tr>
                ))}
              </tbody>
            </table>
    </div>
        </div>
    </div>
  );
};

export default Profile;
