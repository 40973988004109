import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

function AcceptInvite({ user, lobbyId, acceptFn, declineFn }) {
  const [timeLeft, setTimeLeft] = React.useState(25);
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const handleAccept = () => {
    acceptFn(lobbyId);
    setModalIsOpen(false);
  };

  const handleDecline = () => {
    declineFn(lobbyId);
    setModalIsOpen(!modalIsOpen);
  };

  useEffect(() => {
    if (timeLeft > 0) {
      setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    } else {
      declineFn(lobbyId)
    }
  }, [declineFn, lobbyId, timeLeft]);

  return (
    <Modal
      isOpen={modalIsOpen}
      appElement={document.getElementById('modal')}
      overlayClassName="Overlay"
      style={{
        content: {
          display: 'flex',
          flexDirection: 'column',
          width: '25rem',
          margin: ' auto',
          border: 'none',
          borderRadius: 0,
          height: '15rem',
          backgroundColor: 'var(--bg-container)',
        },
      }}
    >
      <div className="d-flex flex-column align-items-center" role="group">
        <h4 className="justify-content-center text-white h4">
          Convidado para uma Sala
        </h4>
        <p className="text-danger font-weight-bold f-play">{timeLeft}</p>
        <div className="container text-white text-center">
          <p>Foste convidado por <span className="text-blue">{user.username}</span> para uma sala.</p>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <button type="button" className="btn btn-invite fr-btn-accept btn-sm" onClick={handleAccept}>
            Aceitar
          </button>
          <button type="button" className="btn btn-invite fr-btn-decline btn-sm ml-3" onClick={handleDecline}>
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default AcceptInvite;
