import { createSlice } from '@reduxjs/toolkit';

export const matchSlice = createSlice({
  name: 'match',
  initialState: {
    foundMatch: false,
    accepts: 0,
    acceptsA: 0,
    acceptsB: 0,
    declines: 0,
    acceptMatch: false,
    matchPhase: 'accept',
    matchId: '',
    currentTeam: '',
    teamBanning: 'a',
    teama: [],
    teamb: [],
    maps: [],
    timer: undefined,
    autoDeclined: false,
    configServer: false,
    connectDisplay: false,
    map: '',
    serverIp: '',
    captain: '',
  },
  reducers: {
    updateServerInfo: (state, action) => {
      state.map = action.payload.map;
      state.connectDisplay = true;
      state.serverIp = action.payload.serverIp;
      const timer = new Date();
      timer.setMinutes(timer.getMinutes() + 5);
      state.timer = timer.toISOString();
    },
    newMatchFound: (state, action) => {
      state.matchId = action.payload.matchId;
      state.foundMatch = action.payload.foundMatch;
      state.matchPhase = action.payload.matchPhase;
      state.acceptMatch= false;
      state.timer = action.payload.timer;
      state.accepts = 0;
      state.acceptsA = 0;
      state.acceptsB = 0;
      state.declines = 0;
      state.accepeted = false;
      state.autoDeclined= false;
      state.configServer = false;
      state.connectDisplay = false;
      state.map = '';
      state.serverIp = '';
    },
    updateMatchFound: (state, action) => {
      state.matchId = action.payload.matchId;
      state.foundMatch = action.payload.foundMatch;
      state.matchPhase = action.payload.matchPhase;
      state.timer = action.payload.timer;
      if (action.payload.matchPhase === 'pickbans') {
        state.teamPicking = action.payload.teamPicking;
        state.maps = action.payload.maps;
        state.teama = action.payload.teamA;
        state.teamb = action.payload.teamB;
        state.timer = action.payload.timer;
        state.currentTeam = action.payload.currentTeam;
        state.captain = action.payload.captain;
      }
      state.accepts = 0;
      state.acceptsA = 0;
      state.acceptsB = 0;
      state.declines = 0;
      state.accepeted = false;
      state.autoDeclined= false;
      state.configServer = false;
      state.connectDisplay = false;
    },
    updateAccepts: (state, action) => {
      state.accepts = action.payload.accepeted;
      state.acceptsA = action.payload.accepetedA;
      state.acceptsB = action.payload.accepetedB;
    },
    updateMatchPhase: (state, action) => {
      state.matchPhase = action.payload;
    },
    updateSelftAccepted: (state) => {
      state.acceptMatch = true;
    },
    updateBans: (state, action) => {
      state.teamBanning = action.payload.teamBanning;
      state.maps = action.payload.maps;
      state.timer = action.payload.timer;
    },
    resetMatch: (state) => {
      state.matchId = '';
      state.currentTeam = '';
      state.teamBanning = 'a';
      state.captain = '';
    },
    cleanUpMatch: (state) => {
      state.foundMatch = false;
      state.accepts = 0;
      state.declines = 0;
      state.acceptMatch = false;
      state.matchPhase = 'accept';
      state.matchId = '';
      state.currentTeam = '';
      state.teamBanning = 'a';
    },
    updateAutoDecline: (state) => {
      state.matchId = '';
      state.foundMatch = false;
      state.autoDeclined = true;
    },
    updateConfigServer: (state, action) => {
      state.configServer = action.payload.configServer;
      state.connectDisplay = false;
    },
  },
});

export const {
  updateMatchFound,
  updateAccepts,
  updateMatchPhase,
  updateSelftAccepted,
  updateBans,
  cleanUpMatch,
  updateAutoDecline,
  updateConfigServer,
  newMatchFound,
  updateServerInfo,
  resetMatch,
} = matchSlice.actions;

export default matchSlice.reducer;
