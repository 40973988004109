import './NotFound.css';

const NotFound = () => {
  return (
      <div>
        <div className="fundal"></div>
        <div className="glitch" data-text="Estas perdido...">
          Estas perdido...
        </div>
      </div>
  );
};

export default NotFound;
