import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function Lobby() {
  const auth = useSelector((state) => state.auth);
  const [queueList, setQueueList] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/queue/list`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((res) => res.json())
      .then((resp) => setQueueList(resp));
  }, [auth.token, setQueueList]);
  return (
    <div className="container">
      <h3>Gerir Queue</h3>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">elo</th>
            <th scope="col">users</th>
          </tr>
        </thead>
        <tbody>
          {queueList.map((q) => (
            <tr key={q.lobbyId}>
              <th>{q.lobbyId}</th>
              <th>{q.elo}</th>
              <td>{q.players.map(p => <div key={`l_${p.steamid}`}>{p.steamid}</div>)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Lobby;
