import { timeAgo } from '../../helpers/date';
import './MatchPreview.css';

const MatchPreview = ({ match }) => {
  return(    
    <div className="match-preview d-flex align-items-center mb-2">
      <div className="match-preview-team text-white d-flex justify-content-center align-items-center"><h4>{match.teamAScore}</h4></div>
      <div className="match-preview-score d-flex flex-column justify-content-center align-items-center py-2">
        <h5 className="text-white d-flex flex-column align-items-center">
          <img src={`/maps/${match.map}.jpg`} alt="mapa" width="50" />
          {match.map}
        </h5>
        <h4 className={`text-white font-weight-normal px-1 d-flex justify-content-center align-items-center ${match.stage}`}>{match.stage}</h4>
        <h5 className="text-white">{timeAgo(match.createdAt)}</h5>
      </div>
      <div className="match-preview-team text-white d-flex justify-content-center align-items-center"><h4>{match.teamBScore}</h4></div>      
    </div>
  );
}

export default MatchPreview;
