import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './Admin.css';

function Admin(props) {
  const auth = useSelector((state) => state.auth);
  let navigate = useNavigate();

  if (!auth.isAuthenticated || !['ADMIN'].includes(auth.user.role)) {
    navigate('/');
    return null;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 py-2 bg-grey text-white">Área de administração</div>
      </div>
      <div className="row bg-white admin-nav p-2">
        <div className="col-md-2">
          <ul className="nav flex-column">
            <li className="nav-item">
              <Link to="lobby">Lobbies</Link>
            </li>
            <li className="nav-item">
              <Link to="queue">Queue</Link>
            </li>
            <li className="nav-item">
              <Link to="server">Servers</Link>
            </li>
            <li className="nav-item">
              <Link to="match">Matches</Link>
            </li>
            <li className="nav-item">
              <Link to="user">Users</Link>
            </li>
          </ul>
        </div>
        <div className="col-md-10">
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default Admin;
