import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../NewModal';
import { WebSocketContext } from '../WebSocket';
import { updateMatchFound, updateSelftAccepted, updateAutoDecline } from '../../store/matchSlice';
import AcceptMatchDialog from './AcceptMatchDialog'
import WaitingPlayers from './WaitingPlayers'
import './AcceptMatch.css'

function AcceptMatch() {
  const selectedMatch = useSelector(state => state.match);
  const ws = useContext(WebSocketContext);
  const dispatch = useDispatch();

  const handleDecline = () => {
    ws.sendCommand('declineMatch', { matchId: selectedMatch.matchId });
    dispatch(updateMatchFound({ id: '', foundMatch: false }));
  }

  const handleAccept = () => {
    ws.sendCommand('acceptMatch', { matchId: selectedMatch.matchId });
    dispatch(updateSelftAccepted());
  }

  const handleAutoDecline = () => {
    ws.sendCommand('declineMatch', { matchId: selectedMatch.matchId });
    dispatch(updateAutoDecline());
  };

  const handleWaitingComplete = () => {
    // TODO: fix this causing window to not show when a player doesnt accept 
    dispatch(updateMatchFound({ id: '', foundMatch: false }))
  }

  const childElement = selectedMatch.acceptMatch ? 
  <WaitingPlayers 
    timer={selectedMatch.timer}
    match={selectedMatch}
    onComplete={handleWaitingComplete}
  /> : 
  <AcceptMatchDialog 
    timer={selectedMatch.timer}
    handleAccept={handleAccept}
    handleDecline={handleDecline}
    autoDeclineFn={handleAutoDecline}
  />;

  return (
    <div>
      <Modal modalIsOpen={true} childElement={childElement} height='20rem' />
    </div>
  );
}

export default AcceptMatch;
