import { createSlice } from '@reduxjs/toolkit';

export const lobbySlice = createSlice({
  name: 'lobby',
  initialState: {
    id: '',
    currentLobby: [],
    inParty: false,
    isLeader: false,
    inQueue: false,
    invited: false,
    invitedby: {},
    invitedLobby:'',
    invitedDate: '',
  },
  reducers: {
    updateFromApi: (state, action) => {
      state.currentLobby = action.payload.lobbyUsers;
      state.id = action.payload.lobbyId;
      state.inParty = true;
      state.isLeader = true;
      state.inQueue = false;
    },
    updateLobby: (state, action) => {
      state.currentLobby = action.payload.currentLobby;
      state.id = action.payload.id;
      state.inParty = true;
      state.isLeader = action.payload.isLeader;
      state.inQueue = false;
    },
    leaveLobby: (state) => {
      state.inParty = false;
      state.currentLobby = [];	
      state.isLeader = false;	
      state.inQueue = false;
    },
    updateQueue: (state, action) => {
      state.inQueue = action.payload;
    },
    updateInvited: (state, action) => {
      state.invited = action.payload.invited;
      state.invitedby = action.payload.invitedby;
      state.invitedLobby = action.payload.id;
      state.invitedDate = new Date();
    },
    clearInvited: (state) => {
      state.invited = false;
      state.invitedby = {};
      state.invitedLobby = '';
      state.invitedDate = '';
    },
    stopQueue: (state) => {
      state.inQueue = false;
    }
  },
});

export const { leaveLobby, updateLobby, updateQueue, updateInvited, stopQueue, clearInvited, updateFromApi } = lobbySlice.actions;

export const leaveLobbyAsync = () => (dispatch) => {
  dispatch(leaveLobby());
}

export const checkLobbyAsync = (lobbyId, lobbyUsers) => (dispatch) => {
  if (!lobbyId) {
    dispatch(leaveLobby());
  } else {
    dispatch(updateFromApi({ lobbyId, lobbyUsers }))
  }
}

export const selectCurrentLobby = (state) => state.lobby;

export default lobbySlice.reducer;
