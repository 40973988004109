import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import WebSocketProvider from './components/WebSocket';
import { store, persistor } from './store';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV !== 'development') {
  ReactGA.initialize('UA-34523612-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
  Sentry.init({
    dsn: "https://f709510f907d4ce187d81bc8d7abeedf@o206223.ingest.sentry.io/5580132",
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
  
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <WebSocketProvider>
            <App />
          </WebSocketProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
