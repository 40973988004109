import { useState } from 'react';
import { Link } from 'react-router-dom';
import ProfileCard from '../ProfileCard';
import MenuItem from '../ui/MenuItem';
import Icon from '../ui/Icon';

const sidebarItems = [
  [
    { id: 'home', title: 'Inicio', notifications: false, to: '/', viewBox: '0 0 8 8' },
    { id: '7', title: 'Classificação', notifications: false, to: '/leaderboard' },
    { id: '0', title: 'Jogos', notifications: false, to: '/matches' },
  ],
  [
    { id: '4', title: 'Criar equipa', notifications: false, to: '' },
    { id: '5', title: 'Denuncias', notifications: false, to: '' },
  ],
];

function Sidebar({ isAuthenticated, handleLogin, user, handleLogout, children, showPlayBtn, acceptFriendFn, declineFriendFn, openLobby }) {
  const [selected, setSelected] = useState('0');
  return (
    <div
      className='fixed inset-y-0 left-0 bg-card w-full w-64 flex-col z-10 flex'
    >
      <div className="flex-shrink-0 overflow-hidden p-2">
        <div className="flex items-center h-full justify-start p-2 sidebar-separator-top">
          <Link to='/'>
            <div className="block ml-2 font-bold text-xl text-white">
              Respawn
            </div>
          </Link>
          <div className="flex-grow block" />
        </div>
      </div>
      {/* TODO: Make this only a single component */}
      <div className="flex-grow overflow-x-hidden overflow-y-auto flex flex-col">
        <div className="w-full p-3 h-24 h-24 flex-shrink-0">
          {isAuthenticated && <div className="bg-sidebar-card-top rounded-xl w-full h-full flex items-center justify-start justify-start px-3 px-3">
            {/* <Icon path="res-react-dash-sidebar-card" className="w-9 h-9 " /> */}
            <div className="block ml-3">
              {children} 
              {showPlayBtn && <button className="flex text-white flex-grow w-64" onClick={openLobby}>
                    <Icon id='group' viewBox='0 0 488.3 488.3' className='w-6 h-6 mr-2' /> Criar Lobby
                </button>}
  
                {/* <FriendsDropdown friendsRequest={user.friendsRequest} acceptFn={acceptFriendFn} declineFn={declineFriendFn}/> */}
              {/* <div className="text-sm font-bold text-white">Party</div>
              <div className="text-sm">Players</div> */}
            </div>
            <div className="block flex-grow" />
          </div>}
        </div>
        {sidebarItems[0].map((i) => (
          <MenuItem
            key={i.id}
            item={i}
            onClick={setSelected}
            selected={selected}
          />
        ))}
        {isAuthenticated && <div className="mt-8 mb-0 font-bold px-3 block">
          Navegação rápida
        </div>}
        {isAuthenticated && sidebarItems[1].map((i) => (
          <MenuItem
            key={i.id}
            item={i}
            onClick={setSelected}
            selected={selected}
          />
        ))}
        <div className="flex-grow" />
        <div className="w-full p-3">
          <div
            className="rounded-xl w-full h-full px-3 overflow-hidden"
            style={{
              backgroundImage: "url('https://assets.codepen.io/3685267/res-react-dash-usage-card.svg')",
            }}
          >
            <div className="block pt-3 pb-3">
              <div className="font-bold text-gray-300 text-sm">Proximo update</div>
              <div className="text-gray-500 text-xs">
                09:12 March 08,2022
              </div>
            </div>

          </div>
        </div>
      </div>
      
      <div className="flex-shrink-0 overflow-hidden p-2">
      {!isAuthenticated ? 
    
    <div onClick={handleLogin} className="steam-green text-gray-100 hover:text-white shadow font-bold text-sm py-3 px-4 rounded flex justify-start items-center cursor-pointer w-58">
        <Icon id='steam' viewBox='0 0 128 128' className="mr-3 w-6 h-5" />
      <span className="border-l border-green-500 h-6 w-1 block"></span>
        <span className="pl-3">Entrar com a Steam</span>
    </div>
           : <ProfileCard user={user} handleLogout={handleLogout} type='logout' />}
      </div>
    </div>
  );
}

export default Sidebar;
