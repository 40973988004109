import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function Lobby() {
  const auth = useSelector((state) => state.auth);
  const [lobbies, setLobbies] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/lobby/list`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`,
      },
    })
      .then((res) => res.json())
      .then((resp) => setLobbies(resp));
  }, [auth.token, setLobbies]);
  return (
    <div className="container">
      <h3>Gerir lobbies</h3>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">users</th>
            <th scope="col">actions</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(lobbies).map((l) => (
            <tr key={l}>
              <th>{l}</th>
              <td>{lobbies[l].map(p => <div key={`l_${p.id}`}><img src={p.picture} width="12" /> {p.username}</div>)}</td>
              <td>delete</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Lobby;
