import { createSlice } from '@reduxjs/toolkit';
import { checkLobbyAsync } from './lobbySlice';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: {},
    token: '',
    onlineFriends: [],
    isAuthenticated: false,
    usernameAlreadyTaken: false,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.isFetching = false;
    },
    updateFriendList: (state, action) => {
      state.onlineFriends = action.payload;
    },
    loginFailure: (state) => {
      state.isAuthenticated = false;
      state.isFetching = false;
    },
    logoutSuccess: (state) => {
      state.user = {};
      state.token = '';
      state.isAuthenticated = false;
    },
    updateUsername: (state, action) => {
      state.user = action.payload.user;
      state.usernameAlreadyTaken = false;
    },
    usernameIsTaken: (state, action) => {
      state.usernameAlreadyTaken = action.payload;
    }
    // logoutRequest: state => state;
  },
});

export const {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  updateFriendList,
  updateUsername,
  usernameIsTaken,
} = authSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(logoutUserAsync())`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const logoutUserAsync = () => (dispatch) => {
  // dispatch(requestLogout());
  dispatch(logoutSuccess());
};

export function friendRequestAsync(id, status) {
  return async function (dispatch, getState) {
    const stateBefore = getState()
    const { token } = stateBefore.auth;

      const response = await fetch(`${process.env.REACT_APP_API}/friend/request`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ id, status }) 
      });
      
      if (response.status === 401) {
        // TODO: display pop with session expire
        dispatch(logoutSuccess());
      }
  
      if (response.status === 200) {
        const user = await response.json();
        dispatch(loginSuccess({ token, user }));
      }
  }
}


export function setUsernameAsync(username) {
  return async function (dispatch, getState) {
    // dispatch(requestLogout());
    const stateBefore = getState()
    const { token } = stateBefore.auth;

      const response = await fetch(`${process.env.REACT_APP_API}/auth/me`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ username }) // body data type must match "Content-Type" header
      });
      
      if (response.status === 401) {
        // TODO: display pop with session expire
        dispatch(logoutSuccess());
      }
  
      if (response.status === 266 || response.status === 400) {
        dispatch(usernameIsTaken(true))
      } else {
        const user = await response.json();
        dispatch(updateUsername(user));
      }

  };
};

export function loginUserAsync(token) {
  return async function(dispatch) {
    const response = await fetch(`${process.env.REACT_APP_API}/auth/me`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    });
    
    if (response.status === 401) {
      // TODO: display pop with session expire
      dispatch(logoutSuccess());
    }

    if (response.status === 200) {
      const { user } = await response.json();
      dispatch(loginSuccess({ token, user }));
      dispatch(checkLobbyAsync(user.lobbyId, user.lobbyUsers));
    }
  };
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.auth.isAuthenticated)`
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectUser = (state) => state.auth.user;

export default authSlice.reducer;
