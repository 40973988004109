import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MatchPreview from '../../components/MatchPreview';
import './MatchList.css';

const MatchList = ({ user }) => {
  const [currentMatchList, setCurrentMatchList] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/match`)
      .then((res) => res.json())
      .then((matches) => setCurrentMatchList(matches))
      .catch((err) => {
        console.error(err);
      });
  }, [setCurrentMatchList]);
  return (
    <div className="container">
        <div className=" pl-0">
          <div className="card d-flex flex-column align-items-center mb-2">
            <h2 className="card-header mb-4">Últimos jogos</h2>
            {currentMatchList.map((match) => (            
              <Link to={`/m/${match.matchId}`} key={match.matchId} className="text-dark link-no-underline mx-3 my-2 w-100 d-flex flex-column align-items-center">
                <MatchPreview match={match} />
              </Link>
            ))}
          </div>
        </div>
    </div>
  );
};

export default MatchList;
