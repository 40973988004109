import Modal from 'react-modal';

const NewModal = ({ modalIsOpen, childElement, height }) => {
  return(
    <Modal
      isOpen={modalIsOpen}
      appElement={document.getElementById('modal')}
      overlayClassName='fixed inset-0 bg-gray-900 bg-opacity-75 z-10 transition-opacity flex justify-center min-h-screen pt-4 text-center'
      className='inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl mt-10 p-4 align-middle max-w-lg w-full h-48'
      style={{
        content: {
          background: '#171717',
        },
      }}
    >
      {childElement}
    </Modal>
  );
}

export default NewModal;
