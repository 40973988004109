import { Link } from 'react-router-dom';
import ProfilePicture from '../../components/ProfilePicture';

const TeamTableStats = ({ teamName, team}) => {
  return(
    <div className="team-table-stats">
      <h4 className="h4 my-4">{teamName}</h4>
      <table className="table table-borderless text-white mb-5">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col" className="text-right font-weight-normal text-uppercase">KDA</th>
            <th scope="col" className="text-right font-weight-normal text-uppercase">kills</th>
            <th scope="col" className="text-right font-weight-normal text-uppercase">headshots</th>
            <th scope="col" className="text-right font-weight-normal text-uppercase">assists</th>
            <th scope="col" className="text-right font-weight-normal text-uppercase">deaths</th>
            <th scope="col" className="text-right font-weight-normal text-uppercase">bombs</th>
            <th scope="col" className="text-right font-weight-normal text-uppercase">defuses</th>
          </tr>
        </thead>
        <tbody>
          {team.map((u) => (
            <tr key={u.id} className="team-table-tr">
              <td className="d-flex align-items-center py-1">
                <ProfilePicture picture={u.picture} size="team-table-player mr-2" />
                <Link to={`/u/${u.username}`}><h4 className="match-player-username my-0">{u.username}</h4></Link>
              </td>
              <td className="text-right">{(((u.stats.kills + u.stats.assist)/u.stats.death) || 0).toFixed(2) }</td>
              <td className="text-right">{u.stats.kills}</td>
              <td className="text-right">{u.stats.headshots}</td>
              <td className="text-right">{u.stats.assist}</td>
              <td className="text-right">{u.stats.death}</td>
              <td className="text-right">{u.stats.bombs}</td>
              <td className="text-right">{u.stats.defuses}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TeamTableStats;
