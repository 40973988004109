import Image from '../ui/Image';

const TeamMember = ({ name, image, invite = false, steamid, inviteFn }) => {
  const handleInvite = () => {
    inviteFn(steamid);
  };

  return (
    <div className="flex items-center">
      <div className="w-10 h-10 flex-shrink-0 mr-1">
        <Image path={image} className="w-8 h-8" />
      </div>
      <div className="font-medium text-white">{name}</div>
      {invite && (
        <button className="ml-1 text-blue-400 flex-grow" onClick={handleInvite}>
          Convidar
        </button>
      )}
    </div>
  );
};

export default TeamMember;
