import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

//import StatusTag from '../../components/StatusTag/StatusTag';
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture';
import { ReactComponent as UserValidated } from '../../components/IconSvg/patch-check.svg';
import { ReactComponent as PersonSVG } from '../../components/IconSvg/person-x-fill.svg';
import './Profile.css';
const Profile = ({ user, isAuthenticated, loggedUser }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [invited, setInvited] = useState(false);
  const auth = useSelector((state) => state.auth);
  let urlParams = useParams();

  const addFriend = async () => {
    // TODO: maybe move this to a reducer
    const response = await fetch(`${process.env.REACT_APP_API}/friend/add`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${auth.token}`
      },
      body: JSON.stringify({ id: currentUser.id }) 
    });
    
    // if (response.status === 401) {
    //   // TODO: display pop with session expire
    //   dispatch(logoutSuccess());
    // }

    if (response.status === 200) {
      setInvited(true);
    }
  }

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/json',
    };
    if (auth.token.length > 0) {
      headers['Authorization'] = `Bearer ${auth.token}`;
    }
    fetch(`${process.env.REACT_APP_API}/user/${urlParams.user}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers,
    })
      .then((res) => res.json())
      .then((profile) => setCurrentUser(profile))
      .catch((err) => {
        console.error(err);
        setCurrentUser({ id: 'not_found' });
      });
  }, [auth.token, setCurrentUser, urlParams.user]);
  return (
    <div className="container">
      {Object.keys(currentUser).length && currentUser.id !== 'not_found' && (
        <div className="card pl-0 d-flex flex-column align-items-center">
          <div className="profile-player py-5 d-flex flex-column align-items-center justify-content-center">
            <ProfilePicture
              picture={currentUser.picture}
              size="profile-player-picture"
            />
            <h4 className="text-secondary mt-2">#{currentUser.id}</h4>
            <h4 className="text-white mb-4 d-flex align-items-center">
              {currentUser.username}
              {currentUser.isVerified && (
                <UserValidated className="ml-2 svg-blue" />
              )}
            </h4>
            {isAuthenticated && currentUser.id !== loggedUser.id && (
              <div>
                <button className="btn btn-profile btn-profile-message mx-2 text-uppercase">
                  Mensagem
                </button>
               {!invited && currentUser.isFriend === 'false' && <button className="btn btn-profile btn-profile-addfriend mx-2 text-uppercase" onClick={() => addFriend()}>
                  Adicionar amigo +
                </button>}
                {(invited || currentUser.isFriend === 'pending') && <button className="btn btn-profile btn-profile-addfriend mx-2 text-uppercase">
                  Convite enviado
                </button>}
              </div>
            )}
          </div>
          <nav className="profile-nav d-flex flex-row justify-content-around py-0 mt-2 text-white">
            <h4 className="font-weight-normal d-flex align-items-center justify-content-center">
              Geral
            </h4>
            <h4 className="font-weight-normal d-flex align-items-center justify-content-center">
              Equipas
            </h4>
            <h4 className="font-weight-normal d-flex align-items-center justify-content-center">
              Jogos
            </h4>
            <h4 className="font-weight-normal d-flex align-items-center justify-content-center">
              Amigos
            </h4>
          </nav>
        </div>
      )}
      {currentUser.id === 'not_found' && (
        <div className="card user-not-found text-white d-flex flex-column align-items-center py-5">
          <PersonSVG />O utilizador não existe.
        </div>
      )}
    </div>
  );
};

export default Profile;
