import { useState, useEffect, Fragment } from 'react';
import Icon from '../ui/Icon';
import { pad } from '../../helpers/date';

const PlayButton = ({ inQueue, playFn, stopFn }) => {
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSecounds] = useState('00');
  const [totalSeconds, setTotalSeconds] = useState(0);
  const handlePlay = () => {
    if (!inQueue) {
      // ensure that everything start again
      setTotalSeconds(0);
      setMinutes('00');
      setSecounds('00');
      playFn();
    }
  };
  // const PlayNow = () => <div className='rounded-md py-1 px-2 text-gray-100 bg-green-500 hover:bg-green-600 focus:outline-none'><Icon id='play' viewBox='0 0 8 8' fill='white' className='w-4 h-4' /> Jogar</div>
  const PlayNow = () => (
    <div className="rounded-md py-1 px-2 text-gray-100 bg-green-500 hover:bg-green-600 focus:outline-none text-xs">
      Jogar
    </div>
  );
  const StopNow = () => (
    <button
      className="rounded-md py-1 px-2 text-gray-100 bg-red-500 hover:bg-red-600 focus:outline-none text-xs ml-2"
      type="button"
      onClick={stopFn}
      data-toggle="tooltip"
      data-placement="bottom"
      title="Sair da fila."
    >
      Parar
    </button>
  );
  const ShowTime = () => (
    <Fragment>
      <span
        className="spinner-border spinner-border-sm text-light"
        role="status"
        aria-hidden="true"
      ></span>
      <span className="text-white ml-2">
        {minutes}:{seconds}
      </span>
    </Fragment>
  );

  useEffect(() => {
    let timerToClean;
    if (inQueue) {
      timerToClean = setTimeout(() => {
        setTotalSeconds(totalSeconds + 1);
        setMinutes(pad(parseInt(totalSeconds / 60)));
        setSecounds(pad(totalSeconds % 60));
      }, 1000);
    }

    return () => {
      clearTimeout(timerToClean);
    };
  }, [inQueue, totalSeconds]);

  return (
    <Fragment>
      <button
        onClick={() => handlePlay()}
        type="button"
        className=" inline-block align-middle"
      >
        {!inQueue && <PlayNow />} {inQueue && <ShowTime />}{' '}
      </button>
      {inQueue && <StopNow />}
    </Fragment>
  );
};

export default PlayButton;
