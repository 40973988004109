import CountDown from '../CountDown';
import MiniProfile from './MiniProfile';
import ServerDetails from '../ServerDetails';
import './PickBans.css';

function PickBans({ match, user, banFn }) {

  const handleBan = (mapName) => {
    banFn(match.matchId, mapName);
  };

  return (
    <div className="d-flex container">
        <div className="col-md-4 d-flex flex-column align-items-start">
          <h3 className="text-white font-weight-normal px-4">Equipa A</h3>
          {match.teama.map(u => <MiniProfile user={u}  key={`user_${u.id}`} />)}
        </div>
        <div className="col-md-4 d-flex flex-column align-items-center">
          {!match.configServer && <div><h2 className="text-white"><CountDown timer={match.timer} onCompleteFn={() => {}} /></h2></div>}
          {!match.configServer && match.teamBanning === match.currentTeam && <h4 className="text-white text-center">É a tua equipa a escolher</h4>}
          {!match.configServer && match.teamBanning !== match.currentTeam && <h4 className="text-white text-center">O teu adversário esta a escolher.</h4>}
          {match.configServer && !match.connectDisplay && <div className="loader"></div>}
          {match.configServer && match.connectDisplay && <ServerDetails serverIp={match.serverIp} map={match.map} timer={match.timer}  />}
          <div className="maps d-flex flex-row justify-content-center">
            {!match.configServer && match.maps.map((a) => (
              <div key={a.name} className={`d-flex flex-column align-items-center m-1 ${a.banned && 'banned'}`}> 
                <button
                  className={`media map-entry d-flex justify-content-center align-items-end p-0 shadow ${match.teamBanning === match.currentTeam && match.captain === user.steamid && !a.banned && 'banning'}`}
                  style={{background: `url(${a.image})`, backgroundSize:"cover"}}
                  onClick={match.teamBanning === match.currentTeam && match.captain === user.steamid && !a.banned ? (() => handleBan(a.name)) : undefined}  
                >
                </button>
                <p className="map-name f-play text-white px-2 mt-0 m-0 text-center">{a.name}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-4 d-flex flex-column align-items-end">
          <h3 className="text-white font-weight-normal px-4">Equipa B</h3>
          {match.teamb.map(u => <MiniProfile user={u} key={`user_${u.id}`} team="b" />)}
        </div>
      </div>
  );
}

export default PickBans;
