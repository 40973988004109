import React, { createContext } from 'react';
import io from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  updateLobby,
  updateQueue,
  updateInvited,
  stopQueue,
} from '../../store/lobbySlice';
import { logoutSuccess, updateFriendList } from '../../store/authSlice';
import {
  updateMatchFound,
  updateAccepts,
  updateBans,
  cleanUpMatch,
  updateConfigServer,
  updateServerInfo,
  newMatchFound,
  resetMatch,
} from '../../store/matchSlice';
import alertSfx from '../AcceptMatch/rooster.mp3';

const toastSettings = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  };
const WebSocketContext = createContext(null);
let socket;
let ws;

export { WebSocketContext };

const WebSocketProvider = ({ children }) => {
  let navigate = useNavigate();
  const alert = new Audio(alertSfx);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const sendCommand = (command, payload) => {
    socket.emit(command, payload);
  };

  if (!socket) {
    socket = io.connect(process.env.REACT_APP_API);

    socket.on('connect', () => {
      if (token && token.length > 0) {
        socket.emit('authorization', { Bearer: token });
      }
    });

    socket.on('createdLobby', (payload) => {
      dispatch(updateLobby(payload));
      dispatch(cleanUpMatch());
    });

    socket.on('friendList', (msg) => {
      dispatch(updateFriendList(msg));
    });

    socket.on('lobbyUpdate', (payload) => {
      dispatch(updateLobby(payload));
    });

    socket.on('invitedToLobby', (payload) => {
      dispatch(updateInvited(payload));
    });

    // inQueue
    socket.on('inQueue', (msg) => {
      dispatch(updateQueue(true));
    });

    // removed from queue
    socket.on('removeQueue', (payload) => {
      dispatch(updateQueue(false));
    });

    socket.on('matchFail', (payload) => {
      toast.dark('Nem todos os jogadores aceitaram o jogo a tempo', toastSettings);
    });
    // matchFound
    socket.on('matchFound', (payload) => {
      dispatch(resetMatch());
      alert.play();
      const timer = new Date();
      timer.setSeconds(timer.getSeconds() + 25);
      dispatch(
        newMatchFound({
          matchId: payload.matchId,
          foundMatch: true,
          matchPhase: 'accept',
          timer: timer.toISOString(), 
        })
      );
    });

    socket.on('accepeted', (payload) => {
      dispatch(updateAccepts(payload));
    });

    socket.on('serverInfo', (payload) => {
      dispatch(updateServerInfo(payload));
    });

    socket.on('pickbans', (payload) => {
      const timer = new Date();
      timer.setSeconds(timer.getSeconds() + 25);
      dispatch(
        updateMatchFound({
          matchId: payload.matchId,
          foundMatch: true,
          matchPhase: 'pickbans',
          teamPicking: 'a',
          currentTeam: payload.currentTeam,
          maps: payload.maps,
          teamA: payload.teamA,
          teamB: payload.teamB,
          captain: payload.captain,
          timer: timer.toISOString(), 
        })
      );
      dispatch(stopQueue());
      navigate(`/ms/${payload.matchId}`);
    });

    socket.on('matchBans', (payload) => {
      const timer = new Date();
      timer.setSeconds(timer.getSeconds() + 27);
      dispatch(updateBans({
        teamBanning: payload.teamBanning,
        maps: payload.maps,
        timer: timer.toISOString(),
      }));
    });

    socket.on('configServer', (payload) => {
      dispatch(updateConfigServer({
        configServer: true,
        connectDisplay: false,
      }));
    });

    socket.on('queueIsFull', () => {
      toast.dark('De momento a queue esta cheia!', toastSettings);
    });

    socket.on('banned', () => {
      toast.dark('Alguem na tua sala esta banido.', toastSettings);
    });

    socket.on('lobbyFull', () => {
      toast.dark('A sala já esta cheia.', toastSettings);
    });
    
    socket.on('lobbyFull', () => {
      toast.dark('A sala já esta cheia.', toastSettings);
    });

    socket.on('invalidToken', () => {
      toast.dark('A tua sessão expirou', toastSettings);
      dispatch(logoutSuccess())
    });
    
    ws = {
      socket,
      sendCommand,
    };
  }

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
