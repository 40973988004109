import React, { useState } from 'react';
import Modal from 'react-modal';
import './SelectUsername.css';

const SelectUsername = ({ user, usernameInUse, clearAlreadyInUse, submitData }) => {

  const [username, setUsername] = useState('');
  const [classInput, setClassInput] = useState('no-input-border');
  const [errors, setErrors] = useState('');

  const handleInputValue = (e) => {
    const re = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
    clearAlreadyInUse();
    setUsername(e.target.value);
    setTimeout(() => {
      if (!re.test(String(e.target.value)) && e.target.value.length > 0) {
        setClassInput('is-invalid');
        setErrors('O teu nome de utilizador não pode ter caracteres especiais.');
      } else if (e.target.value.length < 3) {
        setClassInput('is-invalid');
        setErrors('O teu nome de utilizador tem de ter no mínimo 3 caracteres.');
      } else if (e.target.value.length > 32) {
        setClassInput('is-invalid');
        setErrors('O teu nome de utilizador não pode ter mais de 32 caracteres.');
      } else {
        setClassInput('no-input-border');
        setErrors('');
        setClassInput('is-valid');
      }
    }, 500);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitData(username);
  };

  return (
    <Modal
      isOpen={true}
      appElement={document.getElementById('modal')}
      overlayClassName="Overlay"
      style={{
        content: {
          display: 'flex',
          flexDirection: 'column',
          width: '30vw',
          height: '40vh',
          margin: ' auto',
          border: '1px solid #1B3A4B',
          borderRadius: 0,
          backgroundColor: 'rgb(20,20,20)',
        },
      }}
      ariaHideApp={false}
    >
      <form
        onSubmit={handleSubmit}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <h4 className="text-white text-center h4">Nome de utilizador</h4>
        <p className="text-white ">
          Bem-vindo <span className="text-primary">{user.name}</span>, escolhe o
          nome de utilizador pelo qual irás ser conhecido na comunidade.
        </p>
        <input
          type="text"
          value={username}
          onChange={handleInputValue}
          style={{
            backgroundColor: 'rgb(30, 30, 30)',
          }}
          className={`px-2 py-1 mb-2 text-white form-control ${classInput}`}
          placeholder="Nome de utilizador"
          spellCheck="false"
        />
        {!!errors.length && <p className="text-danger">{errors}</p>}
        {usernameInUse && <p className="text-danger">Nome de utilizador já em uso.</p>}
        <button className="btn btn-primary" disabled={!(errors.length === 0 && !usernameInUse)}>
          Defenir
        </button>
      </form>
    </Modal>
  );
};

export default SelectUsername;
