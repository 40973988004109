import React from 'react'
import './ProfilePicture.css'

const ProfilePicture = ({ picture, alt, size }) => {
    return (
        <img src={picture} alt={alt} className={`profile-picture ${size}`}/>
    )
}

export default ProfilePicture
